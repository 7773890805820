<template>
  <div class="bg-grey-00">
    <div
      v-if="!cartIsEmpty && orderDetails"
      class="min-h-footer-padding pb-32 flex-1"
    >
      <shirtigo-checkout-header />

      <div class="flex">
        <main
          class="h-full w-full lg:m-auto px-4 max-w-container">
          <div class="flex mt-10 mx-3">
            <div class="flex-1">
              <h1 class="text-3xl text-blue-80 font-bold leading-10">
                {{ $t('views.checkout.confirm.headline') }}
              </h1>
            </div>
          </div>

          <cart-overview
            :payment-method="paymentMethod"
          />

          <div class="bg-white border p-6 lg:p-8 mt-6 rounded">
            <div class="border-b border-grey-10">
              <full-price-display />
            </div>

            <div class="lg:flex">
              <div class="lg:flex-1">
                <label class="cursor-pointer text-gray-50 flex mt-8 w-full items-start">
                  <input
                    v-model="agbConfirmed"
                    class="sg-checkbox block mr-2 mt-1"
                    type="checkbox"
                    value="1"
                    required
                  />

                  <span
                    class="block flex-1"
                    v-html="$t('views.checkout.confirm.accept_all_terms_html')"
                  />
                </label>

                <label class="cursor-pointer text-gray-50 flex mt-2 lg:pr-12 w-full items-start">
                  <input
                    v-model="automationConfirmed"
                    class="sg-checkbox block mr-2 mt-1"
                    type="checkbox"
                    value="1"
                    required
                  />

                  <span class="block flex-1">
                    {{ $t('views.checkout.confirm.accept_text') }}
                  </span>
                </label>
              </div>

              <div class="lg:w-5/12 flex items-start">
                <button
                  class="my-3 mt-6 ml-auto text-center px-4 md:px-6 py-2 bg-dark-blue text-white rounded-full font-semibold leading-7 tracking-wide shadow-big min-h-11 xs:text-lg transition-all duration-200 hover:bg-blue-60 font-nunito-sans"
                  @click="submitCheckout"
                >
                  {{ $t('views.checkout.confirm.submit_button') }}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>

    <config-footer />

    <loader-full-page v-if="loading" />

    <alert-modal
      v-if="agbConfirmedAlert"
      @close="agbConfirmedAlert = false"
    >
      <strong>
        {{ $t('views.checkout.confirm.confirm_terms_alert') }}
      </strong>
    </alert-modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ConfigFooter from '@/components/config/ConfigFooter'
  import CartOverview from '@/components/config/checkout/CartOverview'
  import LoaderFullPage from '@/components/layout/LoaderFullPage'
  import ShirtigoCheckoutHeader from '@/components/layout/ShirtigoCheckoutHeader'
  import FullPriceDisplay from '@/components/config/checkout/FullPriceDisplay'
  import AlertModal from '@/components/layout/AlertModal'

  export default {

    data() {
      return {
        paymentMethod: sessionStorage.getItem('payment_method'),
        agbConfirmed: false,
        automationConfirmed: false,
        agbConfirmedAlert: false,
        loading: false
      }
    },

    mounted() {
      if (!this.cart || this.cartIsEmpty || !this.orderDetails) {
        this.loading = true
        setTimeout(this.checkValid, 2000)
      }

      if (this.isCheckedOut) {
        this.deleteCart()
        this.deleteOrderDetails()
        window.location.href = '/'
      }
    },

    methods: {
      checkValid() {
        this.loading = false
        if (!this.cart || this.cartIsEmpty || !this.orderDetails) {
          window.location.href = '/'
        }
      },
      updateAgb(value) {
        this.agbConfirmed = value === '1'
      },
      submitCheckout() {
        window.dataLayer = window.dataLayer || []

        const productItems = this.allCartProductsForCurrentGroup.map((/** @type ApiFormattedProduct */ product) => {
          const baseProduct = this.baseProducts.find(({ id }) => id === product.base_product_id)

          return {
            item_name: baseProduct.name,
            item_id: baseProduct.baseproduct_variant_id,
            quantity: product.variants.reduce((sum, { amount }) => sum + amount, 0),
            price: product.avg_item_price_gross / 100,
          }
        })

        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: this.cart.reference,
            value: this.cart.price_gross / 100,
            tax: this.cart.vat / 100,
            shipping: this.cart.shipping_price_net / 100,
            payment_method: this.paymentMethod,
            currency: 'EUR',
            items: productItems
          }
        })

        if (!this.agbConfirmed) {
          this.agbConfirmedAlert = true
          return
        }

        if (!this.automationConfirmed) {
          this.agbConfirmedAlert = true
          return
        }

        this.loading = true

        if (this.paymentMethod === 'prepayment') {
          this.startPayment({ paymentData: this.paymentData }).then(response => {
            this.$router.push({ 'name': 'thank-you' })
          })
        } else {
          // Complete PayPal Plus Checkout process
          window.location.href = '/api/checkout/payment/submit'
        }
      },
      ...mapActions([
        'startPayment',
        'deleteOrderDetails',
        'deleteCart'
      ])
    },

    computed: {
      paymentData() {
        return {
          address: JSON.stringify(this.orderDetails.shipping_equals_primary ? this.orderDetails.primary : this.orderDetails.shipping),
          orderReference: sessionStorage.getItem('order'),
          paymentMethod: 'prepayment',
        }
      },
      ...mapGetters([
        'orderDetails',
        'cart',
        'allCartProductsForCurrentGroup',
        'isCheckedOut',
        'baseProducts',
        'cartIsEmpty',
      ])
    },

    components: {
      AlertModal,
      FullPriceDisplay,
      ShirtigoCheckoutHeader,
      LoaderFullPage,
      CartOverview,
      ConfigFooter
    },

  }
</script>

<style scoped>
  body {
    background: #f8f9fa;
  }
</style>
