<template>
  <div class="">
    <div class="pb-32 flex-1">
      <div class="flex">
        <main
          class="flex h-full w-full lg:m-auto max-w-container">
          <div class="items-center justify-center flex w-full relative pt-16">
            <div class="flex flex-col">
              <div class="flex flex-col relative">
                <img
                  src="https://uploads-ssl.webflow.com/60ddae90c61b9f8c54e36de7/61487b3cb52cd1408a998fef_shirt-blank.png"
                  alt=""
                  class="mx-auto w-40 sm:w-200px md:w-280px lg:w-360px">
                <div class="text-200 sm:text-300 md:text-400 lg:text-600 opacity-0 h-0 -z-10">
                  404
                </div>
                <div
                  class="absolute w-full -z-10 text-gray-00 leading-none font-extrabold text-200 sm:text-300 md:text-400 lg:text-600 pt-8 sm:pt-0 text-center">
                  404
                </div>
              </div>
              <div class="text-lg flex flex-col justify-center text-center text-gray-70">
                <h2 class="text-center font-bold text-dark-blue text-2xl lg:text-4xl mb-3">
                  Seite wurde nicht gefunden
                </h2>
                <p>
                  Die angeforderte Seite ist nicht vorhanden oder wurde gelöscht.
                </p>
                <a
                  href="/"
                  class="mt-8 mx-auto px-4 md:px-6 py-2 bg-dark-blue text-white rounded-full font-bold leading-7 tracking-wide shadow-big h-11 transition-all duration-200 hover:bg-blue-60 text-base">Zurück zur Startseite</a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
  import ShirtigoFooter from '../components/layout/ShirtigoFooter'
  import ShirtigoHeader from '../components/layout/ShirtigoHeader'

  export default {
    components: {
      ShirtigoHeader,
      ShirtigoFooter
    }
  }
</script>

<style scoped>
body {
  background: #f8f9fa;
}
</style>
