<template>
  <div
    :ref="`boundingBox_${processingItem._internal_id || processingItem.uuid}`"
    :style="style"
    :class="{ 'item-handles': true, outline: isActive }"
    @wheel="onScroll"
  >
    <div
      class="item-handles-wrapper"
      @mousedown="onClick"
    >
      <span
        v-if="processingItem.type !== 'design'"
        class="handle flex z-2 bg-red-70 text-white text-lg top-left cursor-pointer"
        @touchstart.prevent="deleteProcessingItem"
        @mousedown.prevent="deleteProcessingItem"
      >
        <i class="icon-trash-2-outline" />
      </span>

      <span
        v-if="processingItem.type === 'text' || processingItem.type === 'design'"
        class="handle z-2 text-gray-700 bg-white text-lg bottom-left rotate-control hidden md:flex"
      >
        <i class="icon-move-outline" />
      </span>

      <span
        v-if="processingItem.type !== 'design'"
        class="handle flex z-2 text-gray-700 bg-white text-lg top-right cursor-pointer"
        @touchstart.prevent="editProcessingItem"
        @mousedown.prevent="editProcessingItem"
      >
        <i class="icon-edit-outline" />
      </span>

      <!--      <span-->
      <!--        v-if="processingItem.type !== 'design'"-->
      <!--        ref="rotateHandle"-->
      <!--        class="handle flex z-2 text-gray-700 bg-white text-lg top-right rotate-control"-->
      <!--      >-->
      <!--        <i class="icon-refresh-outline" />-->
      <!--      </span>-->

      <span
        ref="resizeHandle"
        class="resize-control z-2"
      >
        <span class="handle flex text-gray-700 text-lg bg-white">
          <i
            class="icon-expand-outline fa-flip-horizontal"
            aria-hidden="true"
          />
        </span>
      </span>
    </div>

    <div
      v-if="isResize"
      class="design-dimensions"
    >
      <span>{{ Math.round(processingItem.width_mm) }} mm</span>
    </div>
  </div>
</template>

<script>
  import interact from 'interactjs'
  import Vue from 'vue'
  import objectContainsChanges from '../../common/utils/objectContainsChanges'

  const ROTATION_SNAPPING_TOLERANCE = 5
  const ROTATION_SNAPPING_ANGLE = 45

  /**
   *  TODO: Refactoring - file is too large and complicated
   */

  export default {

    props: {
      mockupScale: {
        type: Number,
        required: true
      },
      printarea: {
        type: Object,
        required: true
      },
      // Unfortunately we can't type hint this attribute, as it's not available on V8js and would fail
      printareaSvg: { // eslint-disable-line vue/require-prop-types
        required: true
      },
      processingItem: {
        type: Object,
        required: true
      },
      previewElement: {
        type: Array,
        required: false,
        default: () => []
      },
      active: {
        type: Boolean,
        required: true
      }
    },

    data() {
      return {
        style: {},
        draggable: null,
        rotatable: null,
        rotatableData: {
          centerX: null,
          centerY: null,
          angle: 0
        },
        isResize: false,
        isDrag: false,
        isRotate: false,
      }
    },

    mounted() {
      // resize doesn't always fire the end event, so we will use this to make sure
      document.addEventListener('mouseup', this.onResizeEnd)
      document.addEventListener('touchend', this.onResizeEnd)
      document.addEventListener('keydown', this.onKeyDown)

      this.updateScale(this.printWidth)
      this.initializeDragActions()
    },

    beforeUnmount() {
      if (this.draggable) {
        this.draggable.unset()
      }

      if (this.rotatable) {
        this.rotatable.unset()
      }

      document.removeEventListener('mouseup', this.onResizeEnd)
      document.removeEventListener('touchend', this.onResizeEnd)
      document.removeEventListener('keydown', this.onKeyDown)
    },

    methods: {
      // Begin text editing
      onClick() {
        // since dragging will also "click", we need to ensure we're not currently dragging
        setTimeout(() => {
          if (this.isDrag || this.isResize || this.isRotate) {
            return
          }

          this.$emit('processing-item-click', this.processingItem)
        }, 200)
      },
      async initializeDragActions() {
        if (this.draggable) {
          this.draggable.unset()
        }
        if (this.rotatable) {
          this.rotatable.unset()
        }

        if (!this.$refs[`boundingBox_${this.processingItem._internal_id || this.processingItem.uuid}`]) {
          await Vue.nextTick()
        }

        let draggable = null
        const draggableRef = this.$refs[`boundingBox_${this.processingItem._internal_id || this.processingItem.uuid}`]
        if (draggableRef) {
          draggable = interact(draggableRef)

          draggable.draggable({
            listeners: {
              start: this.onDragStart,
              move: this.onDragMove,
              end: this.onDragEnd,
            }
          })

          draggable.resizable({
            edges: {
              top: false,
              left: false,
              bottom: '.resize-control',
              right: '.resize-control'
            },
            listeners: {
              start: this.onResizeStart,
              move: this.onResizeMove,
              end: this.onResizeEnd,
            }
          })
        }

        this.draggable = draggable

        // let rotatable = null
        // const rotatableRef = this.$refs.rotateHandle
        // if (rotatableRef) {
        //   rotatable = interact(rotatableRef)
        //
        //   rotatable.draggable({
        //     onstart: this.onRotateStart,
        //     onmove: this.onRotateMove,
        //     onend: this.onRotateEnd,
        //   })
        // }
        //
        // this.rotatable = rotatable
      },
      // ROTATE
      // onRotateStart(event) {
      //   this.isRotate = true
      //   let rect = this.$refs[`boundingBox_${this.processingItem._internal_id || this.processingItem.uuid}`].getBoundingClientRect()
      //
      //   this.rotatableData.centerX = rect.left + rect.width / 2
      //   this.rotatableData.centerY = rect.top + rect.height / 2
      //   this.rotatableData.angle = this.getDragAngle(event)
      // },
      // async onRotateMove(event) {
      //   let rad = this.getDragAngle(event)
      //   this.updateProcessingItem({
      //     rotation: rad * 180 / Math.PI
      //   })
      //   this.updateContainerPosition()
      // },
      // onRotateEnd(event) {
      //   this.isRotate = false
      //   this.draggable.disabled = false
      //
      //   let rad = this.getDragAngle(event)
      //   this.rotatableData.angle = rad
      //   this.updateProcessingItem({
      //     rotation: rad * 180 / Math.PI
      //   })
      // },
      // getDragAngle(event) {
      //   let startAngle = this.rotatableData.angle || 0
      //   let center = {
      //     x: this.rotatableData.centerX || 0,
      //     y: this.rotatableData.centerY || 0
      //   }
      //   let angle = Math.atan2(center.y - event.clientY, center.x - event.clientX)
      //   let rad = angle - startAngle
      //
      //   let rotation = rad * 180 / Math.PI
      //   // check if we're near the expected value
      //   let mod = Math.abs(rotation) % ROTATION_SNAPPING_ANGLE
      //   if (mod <= ROTATION_SNAPPING_TOLERANCE || mod >= ROTATION_SNAPPING_ANGLE - ROTATION_SNAPPING_TOLERANCE) {
      //     // We're within tolerance. Let's round to the nearest snapping angle and convert back to rad
      //     rad = (Math.round(rotation / ROTATION_SNAPPING_ANGLE) * ROTATION_SNAPPING_ANGLE) * Math.PI / 180
      //   }
      //
      //   return rad
      // },
      // RESIZE
      onResizeStart() {
        this.isResize = true
      },
      async onResizeMove(event) {
        let target = this.$refs[`boundingBox_${this.processingItem._internal_id || this.processingItem.uuid}`]
        if (!target) return
        let { x, y } = target.dataset

        x = (parseFloat(x) || 0) + event.deltaRect.left
        y = (parseFloat(y) || 0) + event.deltaRect.top

        let newWidthInMM = Math.min(Math.max(10, this.convertPixelsToMillimeters(event.rect.width)), Math.round(this.printarea.width))
        this.updateScale(newWidthInMM)

        Object.assign(target.dataset, { x, y })
      },
      onResizeEnd() {
        if (!this.isResize) {
          // Since we register this event globally, it will be triggered for each element
          return
        }
        this.isResize = false
      },
      // DRAG
      onDragStart() {
        if (this.isResize) {
          return
        }
        this.$emit('sticky', true)
        this.isDrag = true
      },
      onDragMove(event) {
        if (this.isResize) {
          return
        }
        this.updateMargin(event.dx, event.dy)
      },
      onDragEnd() {
        this.isDrag = false
        this.$emit('sticky', false)
        this.initializeDragActions()
      },
      onKeyDown(event) {
        if (!this.active) {
          return
        }

        if (document.activeElement.nodeName === 'TEXTAREA') {
          // We are currently focussing a textarea element. Arrow keys may be used to navigate said element
          return
        }

        switch (event.key) {
          case 'ArrowLeft':
            this.updateMargin(-2, 0, false)
            break
          case 'ArrowRight':
            this.updateMargin(2, 0, false)
            break
          case 'ArrowUp':
            this.updateMargin(0, -2, false)
            break
          case 'ArrowDown':
            this.updateMargin(0, 2, false)
            break
          case '+':
          case '=':
            this.updateScale(this.processingItem.width_mm + 4)
            break
          case '-':
            this.updateScale(this.processingItem.width_mm - 4)
            break
          case 'Delete':
            this.deleteProcessingItem()
            break
          default:
            // Don't prevent default if we didn't match a key
            return
        }
        event.preventDefault()
      },
      convertMillimetersToPixels(mm) {
        return mm * this.pixelsPerMillimeter
      },
      convertPixelsToMillimeters(px) {
        return px / this.pixelsPerMillimeter
      },
      updateScale(widthInMM) {
        if (!this.previewElement.length) return
        this.previewElement[0].onResize(widthInMM)

        const widthInPx = this.convertMillimetersToPixels(widthInMM)
        this.updateContainerPosition(null, null, widthInPx)
      },
      onScroll(e) {
        let change = parseInt(e.deltaY) > 0 ? -4 : 4
        this.updateScale(this.processingItem.width_mm + change)
      },
      /**
       * @param {number} offsetLeft
       * @param {number} offsetTop
       * @param {boolean?} enableSnapping
       */
      updateMargin(offsetLeft, offsetTop, enableSnapping = true) {
        const item = this.$refs[`boundingBox_${this.processingItem._internal_id || this.processingItem.uuid}`]
        if (!item) return
        const itemBox = item.getBoundingClientRect()

        let horizontalOffset = offsetLeft
        let verticalOffset = offsetTop

        let printareaSvgBox = this.printareaSvg.getBoundingClientRect()
        if ((itemBox.top + verticalOffset) <= printareaSvgBox.top) {
          verticalOffset = printareaSvgBox.top - itemBox.top
        }
        if ((itemBox.bottom + verticalOffset) >= printareaSvgBox.bottom) {
          verticalOffset = printareaSvgBox.bottom - itemBox.bottom
        }
        if ((itemBox.left + horizontalOffset) <= printareaSvgBox.left) {
          horizontalOffset = printareaSvgBox.left - itemBox.left
        }
        if ((itemBox.right + horizontalOffset) >= printareaSvgBox.right) {
          horizontalOffset = printareaSvgBox.right - itemBox.right
        }

        let posX = this.processingItem.position_x_mm + this.convertPixelsToMillimeters(horizontalOffset)
        let posY = this.processingItem.position_y_mm + this.convertPixelsToMillimeters(verticalOffset)

        if (enableSnapping && Math.abs(posX) < 5) {
          // snap to center
          posX = 0
          horizontalOffset = 0
        }

        this.updateContainerPosition(horizontalOffset, verticalOffset)

        this.updateProcessingItem({
          position_x_mm: posX,
          position_y_mm: posY
        })
      },
      /** @return {Element|null} */
      getSvgElement() {
        if (!this.previewElement || !this.previewElement[0]) return null

        const children = this.previewElement[0].$el.children
        if (!children || children.length === 0 || !children[0].children.length) return null

        return children[0].children[0]
      },
      /**
       * @param {number?} offsetLeft
       * @param {number?} offsetTop
       * @param {number?} width
       */
      updateContainerPosition(offsetLeft, offsetTop, width) {
        const svgElement = this.getSvgElement()
        const handlesParent = document.getElementsByClassName('handles-parent')[0]
        if (!svgElement || !handlesParent) return

        const parentPos = handlesParent.getBoundingClientRect()
        const rectPos = svgElement.getBoundingClientRect()
        const aspectRatio = rectPos.height / rectPos.width

        this.style = {
          left: `${rectPos.left - parentPos.left + (offsetLeft || 0)}px`,
          top: `${rectPos.top - parentPos.top + (offsetTop || 0)}px`,
          width: `${width || rectPos.width}px`,
          height: `${width ? width * aspectRatio : rectPos.height}px`,
        }
      },
      deleteProcessingItem() {
        this.$emit('delete', this.processingItem)
      },
      editProcessingItem() {
        this.$emit('edit', this.processingItem)
      },
      /**
       * @param {Partial<ApiFormattedProcessingItem>} updatedValues
       */
      updateProcessingItem(updatedValues) {
        if (objectContainsChanges(updatedValues, this.processingItem)) {
          this.$emit('element-updated', {
            ...this.processingItem,
            ...updatedValues,
          })
        }
      },
    },

    computed: {
      pixelsPerMillimeter() {
        return this.printareaSvg.getBoundingClientRect().height / this.printarea.height
      },
      isActive() {
        return this.active || this.isDrag || this.isResize || this.isRotate
      },
    },

    watch: {
      active() {
        if (this.active) {
          this.updateContainerPosition()
        }
      },
      processingItem: {
        deep: true,
        handler() {
          this.updateContainerPosition()
        }
      }
    }

  }
</script>

<style>
  .item-handles {
    position: absolute;
  }

  .item-handles-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .design-bounding-box, .item-handles {
    box-sizing: border-box;
    touch-action: none;
    border: 1px dotted transparent;

    .handle {
      opacity: 0;
      justify-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      transition: opacity ease .1s, all .2s ease;

      &.bottom-left {
        position: absolute;
        bottom: -.75rem;
        left: -.75rem;
      }

      &.bottom-right {
        position: absolute;
        bottom: -.75rem;
        right: -.75rem;
      }

      &.top-left {
        position: absolute;
        top: -.75rem;
        left: -.75rem;
      }

      &.top-right {
        position: absolute;
        top: -.75rem;
        right: -.75rem;
      }
    }

    .rotate-control {
      cursor: grab;
    }

    .resize-control {
      background: transparent;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: nwse-resize;
      width: 1.5rem;
      height: 1.5rem;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);

      &.is-drag {
        width: 10em;
        height: 10em;

        .handle {
          opacity: 0;
        }
      }

      .handle {
        position: static;
      }
    }

    .design-dimensions {
      white-space: nowrap;
      color: #ffffff;
      margin-top: .5em;
      text-align: center;
      text-shadow: 0 0 .5em #3e3e3e;
    }
  }

  .design-bounding-box.outline, .item-handles.outline {
    border-color: #3e3e3e !important;
    cursor: move;

    .handle {
      opacity: 1 !important;
    }
  }
</style>
