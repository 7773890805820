<template>
  <a
    :href="cardUrl"
    class="group relative bg-white border border-gray-10 hover:border-gray-30 rounded-lg flex flex-col  p-4 cursor-pointer"
    style="will-change: auto">
    <div class="aspect-w-3 aspect-h-4 sm:aspect-none sm:h-96 mb-4">
      <img
        :src="image"
        alt="Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green."
        class="w-full h-full object-center object-cover sm:w-full sm:h-full">
    </div>
    <div class="flex-1 flex flex-col">

      <h3 class="text-gray-80 leading-7">
        <a :href="cardUrl">
          <span
            aria-hidden="true"
            class="absolute inset-0"></span>
          {{ product.name }}
        </a>
      </h3>
      <span class="text-sm text-gray-50 font-bold leading-5">
        {{ product.brand.data.name }}, {{ product.brand_product_name }}
      </span>
      <div
        v-if="product.variants.data.length > 1"
        class="flex mt-2 mb-3 gap-1 flex-wrap">
        <a
          v-for="variant in product.variants.data"
          :key="variant.id"
          class="h-4 w-4 rounded-box-sm"
          href="#"
          :style="`background-color: #${variant.hexcode};border: 1px solid rgba(0, 0, 0, .2);`"
          :title="variant.color_name"
          @click.prevent
        ><img
          v-if="variant.is_heathered"
          src="/images/heather_texture.png"
          alt="heather_texture"
          class="color-box-heathered"
        /></a>
      </div>
      <div class="flex-1 flex flex-col justify-end">
        <div class="text-base font-bold text-blue-50 flex justify-between">
          {{ $t('global.price_from') }} {{ productPrice | currencyGross(vatMethod, product.platform === 'SG') }}*
          <div class="flex gap-2 flex-wrap">
            <chip
              v-for="method in processingMethods"
              :key="method.key"
            >
              {{ method.name }}
            </chip>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="productTag"
      class="absolute gap-1"
      style="top: .75rem; left: 12px">
      <chip :class="productTag.key">
        {{ productTag.name }}
      </chip>
    </div>
    <div
      class="absolute flex gap-1"
      style="top: 12px; right: 12px">
      <div
        v-if="product.is_24h_express_available"
        class="text-blue-50 h-6 w-6">
        <svg
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path
            fill="currentcolor"
            d="m13.99,7c-.55,0-1,.45-.99,1.01l.02,3.6-2.73,2.69c-.39.39-.4,1.02-.01,1.41.2.2.45.3.71.3s.51-.1.7-.29l3.03-2.98c.19-.19.3-.45.3-.72l-.03-4.02c0-.55-.43-1.01-1.01-.99Z"></path>
          <rect
            fill="currentcolor"
            y="11"
            width="4"
            height="2"></rect>
          <rect
            fill="currentcolor"
            y="8"
            width="4"
            height="2"></rect>
          <rect
            fill="currentcolor"
            y="14"
            width="4"
            height="2"></rect>
          <path
            fill="currentcolor"
            d="m14,3c-4.96,0-9,4.04-9,9s4.04,9,9,9,9-4.04,9-9S18.96,3,14,3Zm0,16c-3.86,0-7-3.14-7-7s3.14-7,7-7,7,3.14,7,7-3.14,7-7,7Z"></path>
        </svg>
      </div>
      <div
        v-if="product.is_organic"
        class="text-green-70 h-6 w-6">
        <svg
          id="Canvas"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M21.27,2.04C14.15,0,7.33,2.34,5.08,7.61c-2.08,4.86-.05,8.71.04,8.87l.04-.02c-1.48,2.78-2.09,5.12-2.13,5.3l1.94.49c.02-.08,1.99-7.68,7.58-11.41l-1.11-1.66c-2.13,1.42-3.76,3.29-5.01,5.15-.34-1.27-.59-3.42.48-5.93,1.53-3.57,6.47-6.23,13.05-4.63-.12,2.16-.77,8.37-4.17,11.47-1.54,1.4-3.41,1.98-5.71,1.77l-.18,1.99c.36.03.72.05,1.06.05,2.4,0,4.48-.78,6.18-2.34,4.79-4.37,4.84-13.33,4.84-13.71,0-.45-.3-.84-.73-.96Z"></path>
        </svg>
      </div>
    </div>
  </a>
</template>

<script>
  export default {

    props: {
      product: {
        type: Object,
      },
      vatMethod: {
        type: String
      }
    },

    data() {
      return {
        cardHovered: false
      }
    },

    computed: {
      productTag() {
        return this.product.attributes.data.find(a => a.type_key === 'product-tag')
      },
      cardUrl() {
        if (this.$i18n && this.$i18n.locale && this.product.platform !== 'SG') {
          return `/${this.$i18n.locale}/catalog/product/${this.product.slug}`
        }

        return `/catalog/product/${this.product.slug}`
      },
      processingMethods() {
        let methods = []
        this.product.processingareas.data.forEach(p => {
          p.processingmethods.data.forEach(pm => {
            if (!methods.find(m => m.key === pm.key)) {
              methods.push(pm)
            }
          })
        })
        return methods
      },
      image() {
        let image = this.product.default_image
        return image.large || image.standard
      },
      productPrice() {
        return this.product.baseprice_processing_default
      },
      availableSizes() {
        let sizes = this.defaultProductVariant.size_variants.data.map(s => s.size)

        if (sizes.length > 1) {
          return sizes[0] + '-' + sizes[sizes.length - 1]
        }

        return sizes[0]
      },
      defaultProductVariant() {
        let sku = this.product.default_variant_sku.split('.')
        let productVariant = this.product.variants.data.find(p => p.id === parseInt(sku[1]))
        if (!productVariant) {
          productVariant = this.product.variants.data[0]
        }
        return productVariant
      }
    },

  }
</script>

<style scoped>
  .product-tag-topseller {
    background-color: #b6e6fe;
    border: 1px solid #b6e6fe;
    color: #15255a;
  }

  .product-tag-recommendation {
    background-color: #fce8ba;
    border: 1px solid #fce8ba;
    color: #332500;
  }

  .product-tag-new {
    background-color: #bcf2cc;
    border: 1px solid #bcf2cc;
    color: #093014;
  }

  .product-tag-discount {
    background-color: #f9b8bc;
    border: 1px solid #f9b8bc;
    color: #360508;
  }
</style>
