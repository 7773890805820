<template>
  <aside class="lg:w-full">
    <h2 class="sr-only">
      {{ $t('global.filter') }}
    </h2>

    <button
      type="button"
      class="flex items-center justify-center w-full px-5 py-2 font-bold text-center bg-white rounded-full lg:hidden shadow-big text-blue-50 hover:text-blue-60 hover:shadow-sm transition-all ease-in-out duration-200"
      @click="filterToggle"
    >
      <span class="mr-1 icon-options-2-outline"></span> {{ $t('views.catalog.filter_products') }}
    </button>

    <div class="hidden lg:block">
      <div>
        <div class="py-6 bg-white shadow-box rounded-box">
          <div class="flex flex-col">
            <a
              class="flex items-center px-6 py-2 border-l-2 cursor-pointer"
              :class="!currentCategory ? 'border-blue-50 text-blue-50 font-bold' : 'text-gray-80 hover:text-blue-50 transform duration-200 ease-in-out border-transparent'"
              @click.prevent="changeCategory(null)"
            >
              {{ $t('views.catalog.all_products') }}
            </a>
            <a
              v-for="(category, index) in productCategories"
              :key="index"
              class="flex items-center group px-6 py-2 border-l-2 cursor-pointer"
              :class="getCategoryKey(category.key) === currentCategory ? 'border-blue-50 text-blue-50 font-bold' : 'text-gray-80 hover:text-blue-50 transform duration-200 ease-in-out border-transparent'"
              @click.prevent="changeCategory(getCategoryKey(category.key))">
              <product-category-icon
                :category="category"
                :active="getCategoryKey(category.key) === currentCategory"
              />
              <span class="ml-2">
                {{ category.name }}
              </span>
            </a>
          </div>
        </div>

        <div class="py-6 mt-4 bg-white shadow-box rounded-box">
          <template v-if="productColors.length">
            <div class="flex flex-col px-6">
              <span class="mb-4 text-lg font-bold text-dark-blue">
                {{ $t('global.colors') }}
              </span>
              <div>
                <color-selector
                  v-for="color in productColors"
                  :key="color.id"
                  :title="color.name"
                  :color="color.hex"
                  :active="!!selectedColors.find(c => c.id === color.id)"
                  :small="true"
                  @click="changeColor(color)" />
              </div>
            </div>
            <hr class="mt-6 mb-6">
          </template>
          <template v-if="genders.length">
            <div class="flex flex-col px-6">
              <span class="mb-4 text-lg font-bold text-dark-blue">
                {{ $t('views.catalog.categories.gender') }}
              </span>
              <div class="flex flex-wrap">
                <filter-chip
                  v-for="gender in genders"
                  :key="gender.key"
                  :active="!!selectedGenders.find(c => c.key === gender.key)"
                  @click="changeGender(gender)"
                >
                  {{ gender.name }}
                </filter-chip>
              </div>
            </div>
            <hr class="mt-6 mb-6">
          </template>
          <template v-if="methods.length">
            <div class="flex flex-col px-6">
              <span class="mb-4 text-lg font-bold text-dark-blue">
                {{ $t('views.catalog.categories.methods') }}
              </span>
              <div class="flex flex-wrap">
                <filter-chip
                  v-for="method in methods"
                  :key="method.key"
                  :active="!!selectedMethods.find(c => c.key === method.key)"
                  @click="changeMethod(method)"
                >
                  {{ method.name }}
                </filter-chip>
              </div>
            </div>
            <hr class="mt-6 mb-6">
          </template>

          <div class="flex flex-col px-6 mb-6">
            <span class="mb-4 text-lg font-bold text-dark-blue">
              {{ $t('global.manufacturer') }}
            </span>

            <fieldset class="space-y-5">
              <div
                v-for="(brand, index) in brands"
                :key="index"
                class="relative flex items-center cursor-pointer">
                <div class="flex items-center h-5">
                  <input
                    :id="brand.reference"
                    aria-describedby="comments-description"
                    :name="brand.name"
                    type="checkbox"
                    class="w-5 h-5 text-indigo-600 border border-gray-30 rounded-box-sm cursor-pointer"
                    :checked="selectedBrands.find(b => b.reference === brand.reference)"
                    @click="$emit('brand:toggle', brand)">
                </div>
                <div class="ml-2">
                  <label
                    :for="brand.reference"
                    class="font-medium text-gray-700 mb-0 cursor-pointer">{{ brand.name }}</label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showFilters"
      class="fixed top-0 left-0 bottom-0 right-0 bg-sg-bg z-50 flex justify-between flex-col">
      <div class="p-4">
        <div class="relative flex justify-between items-center mb-6">
          <div class="text-2xl font-bold text-dark-blue">
            {{ $t('views.catalog.filter_products') }}
          </div>
          <div>
            <button
              class="h-12 w-12 rounded-full bg-white shadow-lg flex justify-center items-center text-2xl text-blue-50"
              @click="filterToggle">
              <span class="icon-close-outline"></span>
            </button>
          </div>
        </div>
        <div
          v-if="productColors.length"
          class="flex flex-col mt-4">
          <div
            class="flex justify-between items-center mb-4"
            @click="showColors = !showColors">
            <div>
              <div class="text-lg font-bold text-dark-blue">
                {{ $t('global.colors') }}
                <span v-if="selectedColors.length">
                  ({{ selectedColors.length }})
                </span>
              </div>
            </div>
            <div class="text-lg text-gray-50">
              <span
                v-if="showColors"
                class="icon-minus-outline mr-2"></span>
              <span
                v-else
                class="icon-plus-outline mr-2"></span>
            </div>
          </div>
          <div v-if="showColors">
            <color-selector
              v-for="color in productColors"
              :key="color.id"
              :title="color.name"
              :color="color.hex"
              :active="!!selectedColors.find(c => c.id === color.id)"
              :small="true"
              @click="changeColor(color)" />
          </div>
        </div>
        <div class="flex flex-col mt-4 mb-4">
          <div
            class="flex justify-between items-center mb-4"
            @click="showGenders = !showGenders">
            <div>
              <div class="text-lg font-bold text-dark-blue">
                {{ $t('views.catalog.categories.gender') }}
                <span v-if="selectedGenders.length">
                  ({{ selectedGenders.length }})
                </span>
              </div>
            </div>
            <div class="text-lg text-gray-50">
              <span
                v-if="showGenders"
                class="icon-minus-outline mr-2"></span>
              <span
                v-else
                class="icon-plus-outline mr-2"></span>
            </div>
          </div>
          <div
            v-if="showGenders"
            class="flex flex-wrap">
            <filter-chip
              v-for="gender in genders"
              :key="gender.key"
              :active="!!selectedGenders.find(c => c.key === gender.key)"
              @click="changeGender(gender)"
            >
              {{ gender.name }}
            </filter-chip>
          </div>
        </div>
        <hr>
        <div class="flex flex-col mt-4 mb-4">
          <div
            class="flex justify-between items-center mb-4"
            @click="showMethods = !showMethods">
            <div>
              <div class="text-lg font-bold text-dark-blue">
                {{ $t('views.catalog.categories.methods') }}
                <span v-if="selectedMethods.length">
                  ({{ selectedMethods.length }})
                </span>
              </div>
            </div>
            <div class="text-lg text-gray-50">
              <span
                v-if="showMethods"
                class="icon-minus-outline mr-2"></span>
              <span
                v-else
                class="icon-plus-outline mr-2"></span>
            </div>
          </div>
          <div
            v-if="showMethods"
            class="flex flex-wrap">
            <filter-chip
              v-for="method in methods"
              :key="method.key"
              :active="!!selectedMethods.find(c => c.key === method.key)"
              @click="changeMethod(method)"
            >
              {{ method.name }}
            </filter-chip>
          </div>
        </div>
        <hr>
        <div class="flex flex-col mt-6 mb-6">
          <div
            class="flex justify-between items-center mb-4"
            @click="showBrands = !showBrands">
            <div>
              <div class="text-lg font-bold text-dark-blue">
                {{ $t('global.manufacturer') }}
                <span v-if="selectedBrands.length">
                  ({{ selectedBrands.length }})
                </span>
              </div>
            </div>
            <div class="text-lg text-gray-50">
              <span
                v-if="showBrands"
                class="icon-minus-outline mr-2"></span>
              <span
                v-else
                class="icon-plus-outline mr-2"></span>
            </div>
          </div>
          <fieldset
            v-if="showBrands"
            class="space-y-5">
            <div
              v-for="(brand, index) in brands"
              :key="index"
              class="relative flex items-center">
              <div class="flex items-center h-5">
                <input
                  :id="brand.reference"
                  aria-describedby="comments-description"
                  :name="brand.name"
                  type="checkbox"
                  class="w-5 h-5 text-indigo-600 border border-gray-30 rounded-box-sm"
                  :checked="selectedBrands.find(b => b.reference === brand.reference)"
                  @click="$emit('brand:toggle', brand)">
              </div>
              <div class="ml-2">
                <label
                  :for="brand.reference"
                  class="font-medium text-gray-700 mb-0">{{ brand.name }}</label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="py-3 px-4 border-t border-gray-20 shadow-nav">
        <button
          type="button"
          class="flex justify-center items-center bg-dark-blue hover:bg-blue-50 text-white shadow-big rounded-full w-full py-2 px-5 text-center hover:shadow-sm font-bold transition-all ease-in-out duration-200"
          @click="filterToggle"
        >
          {{ $t('views.catalog.show_product_count', { amount: catalogProducts.data.length }) }}
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
  import ColorSelector from 'sb/common/components/config/ColorSelector'
  import ProductCategoryIcon from 'sb/common/components/catalog/ProductCategoryIcon'
  import FilterChip from 'sb/elements/Chips/FilterChip'

  export default {
    props: {
      productCategories: {
        type: Array
      },
      productColors: {
        type: Array
      },
      currentCategory: {
        type: String
      },
      selectedColors: {
        type: Array
      },
      brands: {
        type: Array
      },
      genders: {
        type: Array
      },
      selectedGenders: {
        type: Array
      },
      methods: {
        type: Array
      },
      selectedMethods: {
        type: Array
      },
      selectedBrands: {
        type: Array
      },
      catalogProducts: {
        type: Object
      }
    },

    data() {
      return {
        showColors: true,
        showGenders: false,
        showMethods: false,
        showBrands: false,
        showFilters: false
      }
    },

    methods: {
      getCategoryKey(key) {
        if (key.startsWith('creator')) {
          return key.slice(8)
        }

        return key.slice(9)
      },
      filterToggle() {
        this.showFilters = !this.showFilters

        if (this.showFilters) {
          document.body.classList.add('overflow-hidden')
        } else {
          document.body.classList.remove('overflow-hidden')
        }
      },
      changeCategory(category) {
        this.$emit('change:category', category)
      },
      changeColor(color) {
        this.$emit('change:color', color)
      },
      changeMethod(method) {
        this.$emit('change:method', method)
      },
      changeGender(gender) {
        this.$emit('change:gender', gender)
      },
    },

    components: {
      ColorSelector,
      ProductCategoryIcon,
      FilterChip
    },

  }
</script>

<style scoped>
  [type="checkbox"], [type="radio"] {
    padding: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #2563eb;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
  }

</style>
