<template>
  <div>
    <div class="bg-grey-00 min-h-footer-padding pb-32 flex-1">

      <div class="flex px-5 md:px-8 lg:px-10" v-if="this.orderReference">
        <main
          class="h-full w-full lg:m-auto max-w-container"
        >
          <div class="text-center mt-10 mb-10">
            <div class="text-7xl font-bold">
              <i class="icon-checkmark-circle-outline text-green-70" />
            </div>

            <h1 class="text-3xl md:text-5xl text-blue-80 font-extrabold tracking-wide">
              {{ $t('views.thank_you.headline') }}
            </h1>

            <div class="text-lg md:text-xl text-gray-70 mt-5">
              {{ $t('views.thank_you.your_ordernumber') }}: <strong>{{ orderReference }}</strong>.
              <span v-if="paymentMethod === 'prepayment'">{{ $t('views.thank_you.prepayment_notice') }}</span>
            </div>
          </div>

          <div class="mt-10 md:mt-24 mb-10 flex md:block">
            <div
              class="hidden md:block w-full px-24"
              style="margin-bottom: -2rem"
            >
              <div class="border-t border-gray-30 w-full"></div>
            </div>

            <div
              class="md:hidden py-4"
              style="margin-left: 2rem; margin-right: -2rem"
            >
              <div class="border-l border-gray-30 h-full"></div>
            </div>

            <div
              class="flex-1 grid gap-y-4 md:gap-y-0 grid-flow-row md:grid-flow-col grid-cols-3 md:grid-cols-5 grid-rows-5 md:grid-rows-2 w-full text-gray-70"
            >
              <div class="text-4xl md:mx-auto">
                <div class="rounded-full border border-blue-50 bg-blue-50 text-white w-16 h-16 text-center pt-2">
                  <i class="icon-shopping-cart-outline" />
                </div>
              </div>

              <div class="col-span-2 md:col-span-1 md:text-center mt-4 text-xl text-blue-50">
                {{ $t('views.thank_you.order') }}
              </div>

              <div class="text-4xl md:mx-auto">
                <div
                  class="rounded-full border  w-16 h-16 text-center pt-2"
                  :class="paymentMethod === 'prepayment' ? 'border-gray-30 bg-white' : 'border-blue-50 bg-blue-50 text-white'"
                >
                  <i class="icon-checkmark-outline" />
                </div>
              </div>

              <div
                class="col-span-2 md:col-span-1 md:text-center mt-4 text-xl"
                :class="{'text-blue-50' :paymentMethod !== 'prepayment'}"
              >
                {{ $t('views.thank_you.order') }}
              </div>

              <div class="text-4xl md:mx-auto">
                <div class="rounded-full border border-gray-30 w-16 h-16 bg-white text-center pt-2">
                  <i class="icon-printer-outline"></i>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1 md:text-center mt-4 text-xl">
                {{ $t('views.thank_you.production') }}
              </div>
              <div class="text-4xl md:mx-auto">
                <div class="rounded-full border border-gray-30 w-16 h-16 bg-white text-center pt-2">
                  <i class="icon-car-outline"></i>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1 md:text-center mt-4 text-xl">
                {{ $t('views.thank_you.delivery') }}
              </div>
              <div class="text-4xl md:mx-auto">
                <div class="rounded-full border border-gray-30 w-16 h-16 bg-white text-center pt-2">
                  <i class="icon-home-outline"></i>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1 md:text-center mt-4 text-xl">
                {{ $t('views.thank_you.arrival') }}
              </div>
            </div>
          </div>

          <div
            v-if="paymentMethod === 'prepayment'"
            class="text-yellow-70 bg-yellow-00 shadow-xl flex mx-auto max-w-full px-6 py-4 mt-10 leading-8"
          >
            <div class="pr-4 mt-1">
              <span class="icon-info-outline text-xl"></span>
            </div>
            <div class="text-lg">
              {{ $t('views.thank_you.prepayment.text') }}

              <p class="mt-5">
                {{ $t('views.thank_you.prepayment.recipient') }}: <strong>Shirtigo GmbH</strong><br />
                IBAN: <strong>DE10 3707 0024 0397 3385 03</strong><br />
                BIC: <strong>DEUTDEDBKOE</strong><br />
                {{ $t('views.thank_you.prepayment.institute') }}: <strong>Deutsche Bank PKG Köln</strong><br />
                {{ $t('views.thank_you.prepayment.amount') }}: <strong>{{ cart.price_gross | currency }}</strong><br />
                {{ $t('views.thank_you.prepayment.subject') }}: <strong>{{ orderReference }}</strong>
              </p>
            </div>
          </div>

          <div class="border-t border-gray-20 pt-10 mt-10">
            <div class="md:flex items-end">
              <div class="flex-1">
                <h2 class="text-2xl tracking-wide font-bold text-blue-80">
                  {{ $t('views.thank_you.cart_overview.headline') }}
                </h2>
              </div>
              <div class="text-gray-70 mt-5 md:mt-0">
                {{ $t('views.thank_you.cart_overview.ordernumber') }}: {{ orderReference }}
              </div>
            </div>

            <cart-overview
              ref="cartOverview"
              :payment-method="paymentMethod"
              :readonly="true"
            />
          </div>

          <div class="bg-white px-4 pt-6 pb-8 mt-6 shadow-box rounded">
            <full-price-display />
          </div>

          <div class="md:flex mt-16 w-full md:space-x-4 items-stretch space-y-4 md:space-y-0">
            <div class="bg-white w-full md:w-1/2 rounded shadow-sm px-6 py-8 md:flex">
              <div class="mr-6 mb-4">
                <div class="rounded-full bg-blue-50 w-20 h-20 text-5xl text-center pt-2 text-white">
                  <i class="icon-phone-outline" />
                </div>
              </div>

              <div>
                <h3 class="text-2xl font-bold text-dark-blue">
                  {{ $t('views.thank_you.faq.headline') }}
                </h3>

                <p
                  class="mt-4 leading-8 text-gray-70"
                  v-html="$t('views.thank_you.faq.text_html')"
                />
              </div>
            </div>

            <div class="bg-white w-full md:w-1/2 rounded shadow-sm px-6 py-8 md:flex">
              <div class="mr-6 mb-4">
                <div class="rounded-full bg-blue-50 w-20 h-20 text-5xl text-center pt-2 text-white">
                  <i class="icon-email-outline" />
                </div>
              </div>

              <div>
                <h3 class="text-2xl font-bold text-dark-blue">
                  {{ $t('views.thank_you.newsletter.headline') }}
                </h3>

                <p class="mt-4 leading-8 text-gray-70">
                  {{ $t('views.thank_you.newsletter.text') }}
                </p>

                <form
                  class="lg:flex items-center mt-4"
                  method="post"
                  action="https://shirtigo.us9.list-manage.com/subscribe/post?u=6ee5be546cdca9a5d76fa16a1&amp;id=37a71a6511"
                >
                  <div class="flex-1 lg:pr-3">
                    <sg-input
                      v-model="newsletterEmail"
                      name="EMAIL"
                      :required="true"
                      type="email"
                      :placeholder="$t('views.thank_you.newsletter.email')"
                    />
                  </div>
                  <input
                    type="hidden"
                    name="gdpr[36602]"
                    value="Y"
                  />
                  <div
                    style="position: absolute; left: -5000px;"
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_6ee5be546cdca9a5d76fa16a1_37a71a6511"
                      tabindex="-1"
                      value=""
                    />
                  </div>

                  <div>
                    <input
                      type="submit"
                      class="my-3 text-center cursor-pointer w-full px-4 md:px-6 py-2 bg-dark-blue text-white rounded-full font-semibold leading-7 tracking-wide shadow-big min-h-11 xs:text-lg transition-all duration-200 hover:bg-blue-60 font-nunito-sans disabled:opacity-50"
                      :value="$t('views.thank_you.newsletter.submit_button')"
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            class="mt-10 text-gray-50"
            v-html="$t('views.thank_you.newsletter.privacy_notice_html')"
          />
        </main>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import CartOverview from '@/components/config/checkout/CartOverview'
  import ShirtigoHeader from '@/components/layout/ShirtigoHeader'
  import FullPriceDisplay from '@/components/config/checkout/FullPriceDisplay'
  import ShirtigoFooter from '@/components/layout/ShirtigoFooter'

  export default {
    data() {
      return {
        orderReference: sessionStorage.getItem('order'),
        paymentMethod: sessionStorage.getItem('payment_method'),
        newsletterEmail: ''
      }
    },
    mounted() {
      if (!this.orderReference) {
        window.location.href = '/'
      }

      this.markAsCheckedOut()
    },
    methods: {
      ...mapActions([
        'deleteCart',
        'deleteOrderDetails',
        'markAsCheckedOut'
      ])
    },
    computed: {
      ...mapGetters([
        'cart'
      ])
    },
    components: {
      ShirtigoFooter,
      FullPriceDisplay,
      CartOverview,
      ShirtigoHeader
    }
  }
</script>

<style scoped>
  body {
    background: #f8f9fa;
  }
</style>
