<template>
  <div class="flex items-center relative">
    <icon-button @click="open = !open">
      <svg-vue icon="action-menu" />
    </icon-button>

    <transition name="fade">
      <div
        v-if="open"
        class="origin-top-right absolute bg-white shadow rounded-md border border-grey-20 z-50"
        style="width: 260px; top: 55px; right: 10px; box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);"
      >
        <button
          class="flex py-3 px-4 items-center text-grey-80 border-b border-grey-10 font-light"
          @click.prevent="discard"
        >
          <i class="icon-trash-2-outline font-icomoon text-xl mr-2" />

          <span v-if="isMpc">{{ $t('views.designer.discard_collection.headline') }}</span>
          <span v-else>{{ $t('views.designer.discard_order.headline') }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
  import IconButton from '../../buttons/IconButton'
  import { mapActions, mapGetters } from 'vuex'

  export default {

    data() {
      return {
        open: false
      }
    },

    methods: {
      discard() {
        this.$emit('discard')
      },
      ...mapActions([
        'deleteCart',
        'deleteOrderDetails',
        'removeAllManufacturingJobsForGroup',
      ])
    },

    computed: {
      ...mapGetters([
        'cart',
        'isMpc',
        'currentManufacturingJobGroupId'
      ]),
    },

    components: {
      IconButton,
    }

  }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: all .2s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
    transform: scale(.95);
  }
</style>
