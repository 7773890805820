<template>
  <div class="bg-grey-00">
    <div class="pb-32 flex-1">
      <div class="flex px-5 md:px-8 lg:px-10">
        <main class="flex h-full w-full lg:m-auto max-w-container">
          <div class="m-auto w-full pt-2 ">
            <div class="flex mt-2 items-center lg:mt-10">
              <div class="font-bold text-3xl lg:text-4xl flex-1">
                {{ $t('views.cart.headline') }}
              </div>

              <button
                class="my-3 mx-auto w-auto items-center text-center px-4 md:px-6 py-2 border border-blue-50-50 text-blue-50 rounded-full font-semibold leading-6 tracking-wide min-h-10 xs:text-base bg-white transition-all duration-200 hover:border-blue-60 hover:text-blue-60 font-nunito-sans flex"
                :disabled="isLoadingCart || isLoadingPrices ? 'disabled' : false"
                @click="addEmptyManufacturingGroup"
              >
                <i class="icon-plus-outline text-large" />
                <span class="hidden md:block ml-2">{{ $t('views.cart.add_collection') }}</span>
              </button>
            </div>

            <template v-if="orderedCartCollections.length">
              <cart-collection
                v-for="collection in orderedCartCollections"
                :key="collection.groupId"
                :products="collection.products"
                :processings="collection.processings"
                :previews="collection.previews"
                :title="collection.groupName"
                :loading="isLoadingCart || isLoadingPrices"
                :readonly="isLoadingCart"
                @update="updatePrice"
                @edit-collection="editCollection(collection.groupId)"
                @remove-collection="removeCollection(collection.groupId)"
                @remove-product="(baseProductId) => removeProduct(baseProductId, collection.groupId)"
                @edit-product="(baseProductId) => editProduct(baseProductId, collection.groupId)"
              />

              <cart-add-collection-button
                :disabled="isLoadingPrices || isLoadingCart"
                @click="addEmptyManufacturingGroup"
              />

              <cart-footer
                :country="cart.delivery_iso"
                :loading="loading"
                @update-cart="updatePrice"
                @submit="submitCart"
              />
            </template>

            <cart-empty-state v-else />

            <benefits-footer />
          </div>
        </main>
      </div>
    </div>


    <confirm-modal
      v-if="emptyCartConfirm"
      @confirm="emptyCart()"
      @close="emptyCartConfirm = false"
    >
      <strong>{{ $t('views.cart.empty_cart.confirm') }}</strong>
    </confirm-modal>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ConfigFooter from '../../components/config/ConfigFooter.vue'
  import ConfirmModal from '../../components/layout/ConfirmModal'
  import ShirtigoHeader from '../../components/layout/ShirtigoHeader'
  import BenefitsFooter from '../../components/config/BenefitsFooter'
  import Prices from '../../mixins/Prices'
  import Cart from '../../mixins/Cart'
  import CartFooter from '../../components/config/checkout/CartFooter.vue'
  import CartCollection from '../../components/config/checkout/CartCollection.vue'
  import CartEmptyState from '../../components/config/checkout/CartEmptyState.vue'
  import { getDummyManufacturingJob, getEmptyDummyCart } from '@/api/calculation'
  import CartAddCollectionButton from '@/components/config/checkout/CartAddCollectionButton.vue'
  import { findManufacturingJobForProductInGroup } from '@/utils/helpers'
  import { format } from 'date-fns/format'
  import Vue from 'vue'

  export default {

    mixins: [ Prices, Cart ],

    data() {
      return {
        loading: false,
        emptyCartConfirm: false,
      }
    },

    mounted() {
      this.setIsLoadingCart(false)
      this.loading = false

      if (this.isCheckedOut) {
        this.emptyCart()
      }

      if (!this.isMpc) {
        this.removeEmptyProductsOrVariantsFromCart()
      }
    },

    methods: {
      async submitCart() {
        this.loading = true
        this.submitCartAnalytics()
        await this.updateCart()
        await this.$router.push({ name: 'checkout', params: { reference: this.cart.reference } })
      },
      async addEmptyManufacturingGroup() {
        const dummyJob = getDummyManufacturingJob({
          group_id: 0,
          uuid: 0,
          amount: 0,
          created_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          products: [],
          processings: [],
        })

        if (this.cart) {
          this.addManufacturingJob(dummyJob)
        } else {
          await this.saveCart(getEmptyDummyCart([dummyJob]));
        }

        this.setCurrentManufacturingJobUuid(0)
        this.setCurrentManufacturingJobGroupId(0)

        await this.$router.push({ name: 'mpc-calculator', params: { reference: this.cart.reference, groupid: 0 } })
      },
      editCollection(groupId) {
        this.setCurrentManufacturingJobGroupId(groupId)
        this.setCurrentManufacturingJobUuid(this.getJobUuidForGroupId(groupId))

        this.$router.push({ name: 'mpc-collection', params: { reference: this.cart.reference, groupid: groupId } })
      },
      removeCollection(groupId) {
        if (Object.keys(this.cart.manufacturing_jobs.data).length === 1) {
          this.emptyCartConfirm = true
        } else {
          this.removeAllManufacturingJobsForGroup(groupId)
        }

        Vue.nextTick(() => this.updatePrice())
      },
      removeProduct(baseProductId, groupId) {
        this.setCurrentManufacturingJobGroupId(groupId)
        const jobForProduct = findManufacturingJobForProductInGroup(this.cart, groupId, baseProductId)
        this.setCurrentManufacturingJobUuid(jobForProduct.uuid || this.getJobUuidForGroupId(groupId))

        this.removeProductFromJob({
          manufacturingJobUuid: jobForProduct.uuid,
          baseProductId,
        })

        Vue.nextTick(() => this.updatePrice())
      },
      editProduct(baseProductId, groupId) {
        this.setCurrentManufacturingJobGroupId(groupId)
        const jobForProduct = findManufacturingJobForProductInGroup(this.cart, groupId, baseProductId)
        this.setCurrentManufacturingJobUuid(jobForProduct.uuid || this.getJobUuidForGroupId(groupId))

        this.$router.push({
          name: this.isMpc ? 'mpc-designer' : 'single-product-designer',
          params: {
            id: baseProductId,
            reference: this.cart.reference,
            groupid: groupId,
          }
        })
      },
      /**
       * @param {ApiFormattedManufacturingJobGroupId} groupId
       * @return {ApiFormattedManufacturingJobUuid}
       */
      getJobUuidForGroupId(groupId) {
        const firstJobInGroup = this.cart.manufacturing_jobs.data.find((job) => {
          return job.group_id === groupId
        })

        return firstJobInGroup ? firstJobInGroup.uuid : 0
      },
      submitCartAnalytics() {
        window.dataLayer = window.dataLayer || []

        const productItems = this.allCartProducts.map((/** @type ApiFormattedProduct */ product) => {
          const baseProduct = this.baseProducts.find(({ id }) => id === product.base_product_id)

          return {
            item_name: baseProduct.name,
            item_id: baseProduct.baseproduct_variant_id,
            quantity: product.variants.reduce((sum, { amount }) => sum + amount, 0),
            price: product.avg_item_price_gross / 100,
          }
        })

        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            transaction_id: this.cart.reference,
            value: this.cart.price_gross / 100,
            tax: this.cart.vat / 100,
            shipping: this.cart.shipping_price_net / 100,
            payment_method: this.paymentMethod,
            currency: 'EUR',
            items: productItems
          }
        })
      },
      emptyCart() {
        this.deleteCart()
        this.deleteOrderDetails()

        window.location.href = '/'
      },
      ...mapActions([
        'deleteCart',
        'deleteOrderDetails',
        'setCurrentManufacturingJobUuid',
        'setCurrentManufacturingJobGroupId',
        'addManufacturingJob',
        'removeAllManufacturingJobsForGroup',
        'removeProductFromJob',
        'setIsLoadingCart',
      ])
    },

    computed: {
      ...mapGetters([
        'cart',
        'cartIsEmpty',
        'isCheckedOut',
        'allCartProducts',
        'isMpc',
        'isLoadingCart',
        'isLoadingPrices',
        'baseProducts',
        'orderedCartCollections',
      ])
    },

    components: {
      CartEmptyState,
      CartCollection,
      CartFooter,
      BenefitsFooter,
      ShirtigoHeader,
      ConfirmModal,
      ConfigFooter,
      CartAddCollectionButton,
    }

  }
</script>

<style scoped>
  body {
    background: #f8f9fa;
  }
</style>
